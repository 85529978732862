type InjectionLike = {
  id?: string;
};

export const getFocusableInjectionIndex = (injections: ReadonlyArray<InjectionLike>) => {
  let index = 0;
  for (const injection of injections) {
    if (!injection.id) {
      return index;
    }
    index++;
  }
  return 0;
};