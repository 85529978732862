import * as React from 'react';
import { Root, TimeLabel } from './AppFooter.styles';
import { useDay } from '../../../hooks';
import { formatTimestamp } from '../../../utilities';

export const AppFooter: React.FC = () => {
  const { day } = useDay();
  return (
    <Root>
      <TimeLabel>
        Last updated: 
        {formatTimestamp(day)}
      </TimeLabel>
    </Root>
  );
};
