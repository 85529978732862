import * as React from 'react';
import styled from 'styled-components';
import { FaBars, FaSmileWink } from 'react-icons/fa';
import { Themed } from '../../../theme';

export const Root = styled.header`
  background-color: ${(props: Themed) => props.theme.colors.c};
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  z-index: 1;
`;

const Button = styled.button`
  border: 0;
  background: transparent;
  position: absolute;
  top: 1em;
  left: 1em;
  :hover svg path {
    fill: ${(props: Themed) => props.theme.colors.d};
  }
`;

export const MenuButton = (props: React.HTMLProps<HTMLButtonElement>) => (
  <Button onClick={props.onClick} type="button">
    <MenuIcon/>
  </Button>
);

export const MenuIcon = styled(FaBars)`
  fill: white;
  height: 2em;
  width: 2em;
`;

export const SmileIcon = styled(FaSmileWink)`
  position: absolute;
  top: 1em;
  right: 1em;
  fill: white;
  height: 2em;
  width: 2em;
`;

export const DateLabel = styled.div`
  position: absolute;
  left: calc(50% - 30px);
  top: 20px;
  color: ${(props: Themed) => props.theme.colors.d};
`;
