import * as React from 'react';
import { Injection } from '@injections/model';
import { useMemo } from 'react';
import { getInjections } from '../services';
import { useWaiting } from './useWaiting';
import { useContext } from 'react';
import { InjectionActions, injectionsReducer, create } from './injectionsReducer';
import { useEffect } from 'react';

type InjectionState = [ReadonlyArray<Injection>, React.Dispatch<InjectionActions>];

const defaultState: InjectionState = [[], (action) => undefined];

export const InjectionsContext = React.createContext(defaultState);

export const DayInjectionsProvider = (props: { children: React.ReactNode }) => {
  const state = React.useReducer(injectionsReducer, []);
  return (
    <InjectionsContext.Provider value={state}>
      {props.children}
    </InjectionsContext.Provider>
  );
};

export const useInjections = () => {
  const { setIsWaiting } = useWaiting();
  const [injections, dispatch] = useContext(InjectionsContext);
  const refresh = useMemo(() => () => {
    setIsWaiting(true);
    getInjections()
      .then((injections) => {
        dispatch(create.refresh(injections));
      })
      .then(() => setIsWaiting(false));
  }, []);
  useEffect(() => {
    refresh();
  }, []);
  return { injections };
};
