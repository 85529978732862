import * as React from 'react';
import { Injection } from '@injections/model';
import { formatNumericDate } from '../utilities'
import { useInjections } from './useInjections';

export type DaySummary = {
  day: Date;
  injections: ReadonlyArray<Injection>;
};

export const useDaySummaries = () => {
  const { injections } = useInjections();
  const reduction = injections.reduce((prev, next) => {{
    if (next.id && next.done) {
      const date = formatNumericDate(next.done);
      if (!prev[date]) {
        prev[date] = { day: next.done, injections: [] };
      }
      (prev[date].injections as Array<Injection>).push(next);
    }
    return prev;
  }}, {} as Record<string, DaySummary>);
  return Object.keys(reduction).map(key => reduction[key]);
};
