import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Themed } from '../../../theme';

export const MenuItems = styled.div`
  width: 20em;
  svg {
    height: 30px;
    width: 30px;
    margin-bottom: 5px;
    margin-right: 1em;
  }
`;

export const MenuItem = styled(Link)`
  color: ${(props: Themed) => props.theme.colors.c};
  display: block;
  padding: 1em;
  font-size: 1.5em;
  text-decoration: none;
  &&&:hover {
    text-decoration: none;
    cursor: pointer;
    color: ${(props: Themed) => props.theme.colors.d};
    background: ${(props: Themed) => props.theme.colors.c};
  }
`;