import { Injection } from '@injections/model';
import { domain } from './domain';

type InjectionOptions = Omit<Injection, 'id'>;

type Result = { 
  id: string;
}

export const addInjection = (injection: InjectionOptions): Promise<Result> => {
  return fetch(`${domain}/api/v1/injections`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
    body: JSON.stringify(injection)
  })
  .then(body => body.json())
  .then(json => ({ id: json.id as string }))
};
