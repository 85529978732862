import { default as React, useContext, useMemo } from 'react';
import { Injection, InjectionType } from '@injections/model';
import { getInjections, addInjection } from '../services';
import { create } from './injectionsReducer';
import { useEffect } from 'react';
import { useWaiting } from './useWaiting';
import { useDay } from './useDay';
import { InjectionsContext } from './useInjections';

const template: ReadonlyArray<Injection> = [
  { type: InjectionType.Breakfast },
  { type: InjectionType.Lunchtime },
  { type: InjectionType.Dinner },
  { type: InjectionType.Nighttime },
];

type UseDayInjectionsOptions = {
  shouldRefresh?: boolean;
};

export const useDayInjections = (options?: UseDayInjectionsOptions) => {
  const shouldRefresh = options && options.shouldRefresh || false;
  const { isWaiting, setIsWaiting } = useWaiting();
  const [state, dispatch] = useContext(InjectionsContext);
  const { setDay } = useDay();
  
  const refresh = useMemo(() => (day: Date) => {
    const date = setDay(day);
    setIsWaiting(true);
    dispatch(create.refresh(template));
    getInjections({ date })
      .then((injections) => {
        const result = template.map((templateInjection) => {
          const injectionFromDb = injections.find(injection => injection.type === templateInjection.type);
          return injectionFromDb || templateInjection;
        });
        dispatch(create.refresh(result));
      })
      .then(() => setIsWaiting(false));
  }, []);
  
  useEffect(() => {
    shouldRefresh && refresh(new Date());
    const onVisibility = () => {
      if (document.visibilityState === 'prerender' || document.visibilityState === 'visible') {
        refresh(new Date());
      }
    };
    document.addEventListener('visibilitychange', onVisibility);
    return () => {
      document.removeEventListener('visibilitychange', onVisibility);
    };
  }, [shouldRefresh]);
  
  const complete = (injection: Injection) => {
    setIsWaiting(true);
    addInjection(injection)
      .then(({ id }) => {
        dispatch(create.updateByType([{ id, ...injection }]))
      })
      .finally(() => setIsWaiting(false));
  };
  
  return {
    injections: state,
    isWaiting,
    complete
  };
};
