import * as React from 'react';
import { Root, MenuButton, DateLabel } from './AppHeaderStyle';
import { useDay, useMenu } from '../../../hooks';
import { useCallback } from 'react';
import { formatDayTextMonth } from '../../../utilities';

export const AppHeader: React.FC = () => {
  const { day } = useDay();
  const { open, setOpen } = useMenu();
  const onClick = useCallback(() => setOpen(!open), [open]);
  return (
    <Root>
      <MenuButton onClick={onClick}/>
      <DateLabel>{formatDayTextMonth(day)}</DateLabel>
    </Root>
  );
};
