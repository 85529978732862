import * as React from 'react';
import { render } from '@testing-library/react';
import { theme } from '../theme';
import { Providers } from '../Providers';
import { BrowserRouter as Router } from "react-router-dom";

export const renderWithProviders = (Component: React.ReactNode) => {
  return render(
    <Providers theme={theme}>
      <Router>
        <>{Component}</>
      </Router>
    </Providers>
  );
};
