import { default as React, createContext, useReducer } from 'react';
import { useContext } from 'react';

const defaultState: WaitingState = [false, () => undefined];

type WaitingState = [boolean, React.Dispatch<boolean>];

const WaitingContext = createContext(defaultState);

const reducer = (state: boolean, action: boolean) => action;

export const WaitingProvider = (props: { children: React.ReactNode }) => {
  const state = useReducer(reducer, false);
  return (
    <WaitingContext.Provider value={state}>
      {props.children}
    </WaitingContext.Provider>
  );
};

export const useWaiting = () => {
  const [isWaiting, setIsWaiting] = useContext(WaitingContext);
  return { isWaiting, setIsWaiting };
};
