import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import {
  DayInjectionsProvider,
  WaitingProvider,
  DayProvider,
  MenuContextProvider
} from './hooks';
import { Theme } from './theme';

export type ProviderProps = {
  theme: Theme;
};

export const Providers: React.FC<ProviderProps> = ({ theme, children }) => (
  <ThemeProvider theme={theme}>
    <DayProvider>
      <DayInjectionsProvider>
        <WaitingProvider>
          <MenuContextProvider>
            {children}
          </MenuContextProvider>
        </WaitingProvider>
      </DayInjectionsProvider>
    </DayProvider>
  </ThemeProvider>
);
