export interface Themed {
  theme: Theme;
};

export interface Theme {
  colors: {
    a: string;
    b: string;
    c: string;
    d: string;
    e: string;
    f: string;
  },
  breakpoints: {
    xs: string;
  }
}

export const theme: Theme = {
  colors: {
    a: '#ffd1f0',
    b: '#FF7FD5',
    c: '#FE43C1',
    d: 'white',
    e: 'black',
    f: '#6CDA90',
  },
  breakpoints: {
    xs: '480px',
  }
};
