import * as React from 'react';
import { Content } from './styles';
import { AppHeader } from '../../sections';
import { AppFooter } from '../../sections';
import { Menu } from '../../controls/Menu';

export const Page: React.FC = ({ children }) => {
  return (
    <Menu>
      <AppHeader/>
      <Content>
        {children}
      </Content>
      <AppFooter/>
    </Menu>
  );
};
