export const formatDayTextMonth = (date: Date) => {
  return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });
};

export const formatDayTextMonthYear = (date: Date) => {
  return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
};

export const formatTimestamp = (date: Date) => {
  return date.toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric', second: 'numeric' });
};

export const formatNumericDate = (date: Date) => {
  return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' });
};