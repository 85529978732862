import * as React from 'react';
import Sidebar from 'react-sidebar';
import { useMenu } from '../../../hooks';
import { MenuItem, MenuItems } from './Menu.style';
import { useCallback } from 'react';

export const Menu: React.FC = ({ children }) => {
  const { open, setOpen } = useMenu();
  const onClick = useCallback(() => setOpen(false), [open]);
  return (
    <Sidebar
      sidebar={
        <MenuItems>
          <MenuItem to="/" onClick={onClick}>
            Home
          </MenuItem>
          <MenuItem to="/history/" onClick={onClick}>
            History
          </MenuItem>
        </MenuItems>
      }
      open={open}
      styles={{
        sidebar: {
          background: 'white',
          position: 'fixed',
        },
      }}
      contentId='my-content'
      onSetOpen={() => setOpen(false)}
    >
      {children}
    </Sidebar>
  );
};