import * as React from 'react';
import { Root } from './InjectionSummary.style';
import { Injection } from '@injections/model';
import { formatDayTextMonthYear } from '../../../utilities';
import { getIcon } from './getIcon';
import { DateLabel, Icons, IconHolder } from './InjectionSummary.style';
import { Tick } from './InjectionSummary.style';

export type InjectionSummaryProps = {
  day: Date;
  injections: ReadonlyArray<Injection>;
};
 
export const InjectionSummary: React.FC<InjectionSummaryProps> = ({ day, injections }) => {
  const allDone = injections.length === 4;
  return (
    <Root>
      <DateLabel>{formatDayTextMonthYear(day)}</DateLabel>
      <Icons>
        {injections.map(({ type, id }) => {
          const Icon = getIcon(type);
          return (
            <IconHolder key={id}>
              <Icon />
            </IconHolder>
          );
        })}
      </Icons>
      {allDone && <Tick />}
    </Root>
  );
};
