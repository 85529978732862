import styled, { css } from 'styled-components';
import { Themed } from '../../../theme';
import { FaMoon, FaBreadSlice, FaHamburger, FaSyringe, FaCoffee, FaCheckCircle } from 'react-icons/fa';

export const LeftBlock = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding-right: 2em;
  width: 40px;
`;

export const RightBlock = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

export const Root = styled.div`
  position: relative;
  display: table;
  background: ${(p: Themed) => p.theme.colors.a};
  border-radius: 5px;
  padding: 2em;
  width: 100%;
`;

export const Name = styled.h2`
  color: ${(p: Themed) => p.theme.colors.b};
  font-size: 20px;
`;

export const YesButton = styled.button`
  background: ${(p: Themed) => p.theme.colors.c};
  color: ${(p: Themed) => p.theme.colors.d};
  padding: 0.5em;
  min-width: 3em;
  border: 0;
  outline: none;
`;

export const QuestionText = styled.p`
  color: ${(p: Themed) => p.theme.colors.b};
`;

export const DoneText = styled.h2`
  color: ${(p: Themed) => p.theme.colors.b};
  font-size: 40px;
`;

export const DoneDate = styled.div`
  color: ${(p: Themed) => p.theme.colors.b};
  font-size: 15px;
`;

export const Tick = styled(FaCheckCircle)`
  position: absolute;
  right: 15px;
  top: 15px;
  height: 40px;
  width: 40px;
  color: ${(p: Themed) => p.theme.colors.f};
`;

const icon = css`
  display: inline-block;
  margin: 0 auto;
  color: ${(p: Themed) => p.theme.colors.b};
  height: 40px;
  width: 40px;
`;

export const BreakfastIcon = styled(FaCoffee)`${icon}`;
export const LunchIcon = styled(FaBreadSlice)`${icon}`;
export const NightIcon = styled(FaMoon)`${icon}`;
export const DinnerIcon = styled(FaHamburger)`${icon}`;
export const AdditionalIcon = styled(FaSyringe)`${icon}`;

