import styled, { css, keyframes } from 'styled-components';
import { Themed } from '../../../theme';
import { FaSpinner } from 'react-icons/fa';

const frames = keyframes`
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;

const icon = css`
  display: inline-block;
  margin: 0 auto;
  color: ${(p: Themed) => p.theme.colors.b};
  height: 40px;
  width: 40px;
  animation: ${frames} 1s linear infinite;
`;

export const Root = styled.div`
  position: relative;
`;

export const WaitText = styled.div`
  position: absolute;
  top: 5px;
  left: 50px;
  color: ${(p: Themed) => p.theme.colors.b};
`;

export const SpinnerIcon = styled(FaSpinner)`${icon}`;