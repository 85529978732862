import * as React from 'react';
import { Page } from '../Page';
import { InjectionTile } from '../../controls';
import { StandardSection } from '../../sections';
import { useDayInjections, useScrollTo } from '../../../hooks';
import { getFocusableInjectionIndex } from './getFocusedInjection';
import { Injection } from '@injections/model';

const unreadInjection = (injections: ReadonlyArray<Injection>) => () =>
  `[data-testid="injection"]:nth-child(${getFocusableInjectionIndex(injections)+1})`;

export const MainPage: React.FC = () => {
  const { injections } = useDayInjections({ shouldRefresh: true });
  useScrollTo(unreadInjection(injections), [injections]);
  return (
    <Page>
      <StandardSection>
        {injections.map((injection, index) => (
          <InjectionTile 
            id={injection.id}
            key={index}
            done={injection.done}
            type={injection.type}
          />
        ))}
      </StandardSection>
    </Page>
  );
};
