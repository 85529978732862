import * as React from 'react';
import { Page } from '../Page';
import { StandardSection } from '../../sections';
import { useDaySummaries, DaySummary } from '../../../hooks';
import { InjectionSummary } from '../../controls';

const byLatestFirst = (a: DaySummary, b: DaySummary) => {
  if (a.day.getTime() < b.day.getTime()) 
    return 1;
  else if (a.day.getTime() > b.day.getTime()) {
    return -1;
  }
  return 0;
};

export const HistoryPage: React.FC = () => {
  const summaries = useDaySummaries().sort(byLatestFirst);
  return (
    <Page>
      <StandardSection>
        {summaries.map((summary) => (
          <InjectionSummary
            key={summary.day.toString()}
            {...summary}
          />
        ))}
      </StandardSection>
    </Page>
  )
};
