import styled, { css } from 'styled-components';
import { Themed } from '../../../theme';
import { FaMoon, FaBreadSlice, FaHamburger, FaSyringe, FaCoffee, FaCheckCircle } from 'react-icons/fa';

export const Root = styled.div`
  background: ${(p: Themed) => p.theme.colors.a};
  position: relative;
  border-radius: 5px;
  padding: 1em;
  margin-bottom: 1em;
  width: 100%;
`;

export const DateLabel = styled.span.attrs({ 'data-testid': 'date' })`
  color: ${(p: Themed) => p.theme.colors.b};
`;

export const Icons = styled.span`
  position: absolute;
  display: inline-block;
  right: 50px;
`;

export const Tick = styled(FaCheckCircle).attrs({ 'data-testid': 'done-icon'})`
  position: absolute;
  right: 15px;
  top: 15px;
  height: 30px;
  width: 30px;
  color: ${(p: Themed) => p.theme.colors.f};
`;

const icon = css`
  margin: 0 auto;
  color: ${(p: Themed) => p.theme.colors.b};
  height: 20px;
  width: 20px;
`;

export const IconHolder = styled.div`
  margin-right: 10px;
  display: inline-block;
`;

export const BreakfastIcon = styled(FaCoffee).attrs({ 'data-testid': 'breakfast-icon' })`${icon}`;
export const LunchIcon = styled(FaBreadSlice).attrs({ 'data-testid': 'lunch-icon' })`${icon}`;
export const NightIcon = styled(FaMoon).attrs({ 'data-testid': 'night-icon' })`${icon}`;
export const DinnerIcon = styled(FaHamburger).attrs({ 'data-testid': 'dinner-icon' })`${icon}`;
export const AdditionalIcon = styled(FaSyringe).attrs({ 'data-testid': 'additionl-icon' })`${icon}`;
