import { Injection } from '@injections/model';
import { domain } from './domain';

const parseDateString = (value: string) => {
  const date = new Date(value);
  date.setUTCHours(0);
  return date;
};

export const toInjection = (jsonBody: any): Injection => {
  return {
    id: jsonBody.id,
    done: parseDateString(jsonBody.done),
    type: jsonBody.type
  }
};

type GetInjectionsOptions = { date?: Date };

export const getInjections = (options?: GetInjectionsOptions): Promise<readonly Injection []> => {
  let parameter = '';
  if (options && options.date) {
    const { date } = options;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    parameter = `?date=${year}-${month}-${day}`;
  }
  return fetch(`${domain}/api/v1/injections${parameter}`)
    .then(body => body.json())
    .then((jsonCollection: any) => jsonCollection.map(toInjection))
};
