import { InjectionType } from '@injections/model';
import { 
  BreakfastIcon,
  LunchIcon,
  DinnerIcon,
  NightIcon,
  AdditionalIcon
} from './InjectionSummary.style';

export const getIcon = (type: InjectionType) => {
  switch (type) {
    case InjectionType.Breakfast: return BreakfastIcon;
    case InjectionType.Lunchtime: return LunchIcon;
    case InjectionType.Dinner: return DinnerIcon;
    case InjectionType.Nighttime: return NightIcon;
    case InjectionType.Additional: return AdditionalIcon;
  }
}
