import { Injection } from '@injections/model';

export enum InjectionActionsEnum {
  update = 'injections/update',
  refresh = 'injections/refresh'
}

export type Update = {
  type: InjectionActionsEnum.update;
  injections: readonly Injection [];
}

export type Refresh = {
  type: InjectionActionsEnum.refresh;
  injections: readonly Injection [];
}

export type InjectionActions = Update | Refresh;

export const create = {
  updateByType: (injections: readonly Injection []): Update => ({
    type: InjectionActionsEnum.update,
    injections
  }),
  refresh: (injections: readonly Injection []): Refresh => ({
    type: InjectionActionsEnum.refresh,
    injections
  })
};

export const injectionsReducer: React.Reducer<readonly Injection [], InjectionActions> = (state, action) => {
  let newState: Injection [] | undefined = undefined;
  if (action.type === InjectionActionsEnum.update) {
    action.injections.forEach(injection => {
      const oldIndex = state.findIndex(item => item.type === injection.type);
      const newInjection = injectionReducer(state[oldIndex], injection);
      if (newInjection !== state[oldIndex] && !newState) {
        newState = [...state];
      }
      if (newState) {
        if (oldIndex === -1) {
          newState.push(newInjection)
        } else {
          newState[oldIndex] = newInjection;
        }
      }
    });
    return newState && newState !== state ? newState : state;
  } else if (action.type === InjectionActionsEnum.refresh) {
    return action.injections;
  }
  return state;
};

const injectionReducer = (state: Injection, action: Injection) => {
  return action;
};
