import styled from 'styled-components';

export const StandardSection = styled.section`
  padding-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  & > * {
    margin-bottom: 2em;
  }
`;
