var InjectionType;
(function (InjectionType) {
    InjectionType["Breakfast"] = "b";
    InjectionType["Lunchtime"] = "l";
    InjectionType["Dinner"] = "d";
    InjectionType["Nighttime"] = "n";
    InjectionType["Additional"] = "a";
})(InjectionType || (InjectionType = {}));
var getInjectionTypeName = function (value) {
    switch (value) {
        case InjectionType.Additional: return 'Additional';
        case InjectionType.Breakfast: return 'Breakfast';
        case InjectionType.Lunchtime: return 'Lunch time';
        case InjectionType.Dinner: return 'Dinner';
        case InjectionType.Nighttime: return 'Night time';
    }
};

export { InjectionType, getInjectionTypeName };
