import { useLayoutEffect } from 'react';
import { getScrollerFor } from '../utilities';

export const useScrollTo = (getSelector: () => string, deps?: ReadonlyArray<any>) => {
  useLayoutEffect(() => {
    const scroller = getScrollerFor('#my-content');
    setTimeout(() => {
      scroller.to(getSelector(), { offset: -100 });
    }, 1000);
  }, deps);
};
