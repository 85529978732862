import styled from 'styled-components';
import { Themed } from '../../../theme';

export const Root = styled.header`
  position: relative;
  background-color: ${(props: Themed) => props.theme.colors.c};
  height: 150px;
  margin-top: 300px;
`;

export const TimeLabel = styled.div`
  position: absolute;
  left: calc(50% - 50px);
  top: 20px;
  color: ${(props: Themed) => props.theme.colors.d};
  font-size: 10px;
`;
