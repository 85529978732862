import React from 'react';
import { MainPage, HistoryPage } from './components/pages';
import { BrowserRouter as Router, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { theme } from './theme';
import { Providers } from './Providers';

const Routes = () => {
  return (
    <Router>
      <Route path='/' exact={true} component={MainPage}/>
      <Route path='/history' component={HistoryPage}/>
    </Router>
  );
};

export const App = () => {
  return (
    <Providers theme={theme}>
      <Routes/>
    </Providers>
  );
};
