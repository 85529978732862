import * as React from 'react';
import { useState, createContext, useContext } from 'react';

const MenuContext = createContext<[boolean, React.Dispatch<React.SetStateAction<boolean>>]>(
  [false, () => undefined]);

export const MenuContextProvider: React.FC = ({ children }) => {
  const state = useState(false);
  return (
    <MenuContext.Provider value={state}>
      {children}
    </MenuContext.Provider>
  )  
};

export const useMenu = () => {
  const [open, _setopen] = useContext(MenuContext);
  return {
    open,
    setOpen: (open: boolean) => {
      _setopen(open);
      return open;
    },
  };
}