import { default as React, createContext, useReducer, useContext } from 'react';

type DayState = [Date, React.Dispatch<Date>];

const defaultState: DayState = [new Date, () => undefined];

const DayContext = createContext(defaultState);

const reducer = (state: Date, action: Date) => action;

export const DayProvider = (props: { children: React.ReactNode }) => {
  const state = useReducer(reducer, new Date());
  return (
    <DayContext.Provider value={state}>
      {props.children}
    </DayContext.Provider>
  );
};

export const useDay = () => {
  const [day, _setDay] = useContext(DayContext);
  return {
    day,
    setDay: (date: Date) => {
      _setDay(date);
      return date;
    },
  };
}
