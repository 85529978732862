import React, { useCallback } from 'react';
import { Root, Name, DoneText, RightBlock, YesButton, QuestionText, LeftBlock, DoneDate, Tick } from './InjectionStyles';
import { InjectionType, getInjectionTypeName } from '@injections/model';
import { getIcon } from './getIcon';
import { useDayInjections } from '../../../hooks';
import { Waiter } from '../Waiter';
import { formatTimestamp } from '../../../utilities';

export interface Props {
  id?: string
  done?: Date;
  type: InjectionType;
}

export const InjectionTile: React.FC<Props> = ({ id, type, done }) => {
  const Icon = getIcon(type);
  const name = getInjectionTypeName(type);
  const { complete, isWaiting } = useDayInjections();
  const onClick = useCallback(() =>
    complete({ type, done: new Date() }), [id, type]);
  const isDone = id && !isWaiting;
  return (
    <Root data-testid='injection'>
      <LeftBlock>
        <Icon/>
      </LeftBlock>
      <RightBlock>
        <Name>{name}</Name>
        {isWaiting && <Waiter/>}
        {isDone && (
          <>
            <DoneText>DONE</DoneText>
            <DoneDate>
              {done && formatTimestamp(done)}
            </DoneDate>
          </>
        )}
        {!isDone && (
          <>
            <QuestionText>{`Have you done your ${name} injection?`}</QuestionText>
            <YesButton onClick={onClick}>Yes</YesButton>
          </>
        )}
      </RightBlock>
      {isDone && <Tick />}
    </Root>
  );
};
